////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgb(255, 255, 255);
}
.bg-secondaryColor {
  background-color: rgb(223, 215, 197);
}
.bg-thirdColor {
  background-color: rgb(68, 72, 72);
}
.bg-fourthColor {
  background-color: rgb(68, 72, 72);
}
.bg-fifthColor {
  background-color: rgb(223, 215, 197);
}
.bg-sixthColor {
  background-color: rgb(68, 72, 72);
}
.bg-seventhColor {
  background-color: rgb(68, 72, 72);
}
